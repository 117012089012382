<template>
  <v-dialog persistent v-model="modal" width="630">
    <v-card class="elevation-12">
      <v-toolbar dark color="primary">
        <v-avatar size="45">
          <v-img max-height="500" max-width="500" src="../assets/netkod.png" />
        </v-avatar>
        <v-toolbar-title class="ml-3">Sayaç Sınav Merkezi</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-5">
        <v-form>
          <!-- <v-select
            v-if="modal"
            :v-model="kurum_id"
            :value="ilk_kurum"
            :items="data"
            item-text="ad"
            item-value="id"
            prepend-icon="mdi-home"
            label="Kurum"
            clearable
            return-object
            @change="(i) => kurum_secildi(i)"
            @keydown="enter"
          /> -->
          <v-select
            v-if="modal"
            :v-model="sube_id"
            :value="ilk_sube"
            :items="data"
            item-text="ad"
            item-value="id"
            prepend-icon="mdi-home"
            label="Şube"
            clearable
            return-object
            @change="(i) => sube_secildi(i)"
            @keydown="enter"
          />
          <v-select
            v-if="modal"
            :v-model="donem_id"
            :value="ilk_donem"
            :items="gelen_donemler"
            item-text="ad"
            item-value="id"
            prepend-icon="mdi-clipboard-text-clock"
            label="Dönem"
            clearable
            return-object
            @change="donem_secildi"
            @keydown="enter"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" dark @click="cikis()"> ÇIKIŞ </v-btn>
        <v-btn color="primary" @click="giris()"> GİRİŞ </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { kullanici_donem_izinleri } from "@/query/kullanicilar";

export default {
  name: "SubeDonem",

  methods: {
    async giris() {
      if (this.sube_id === null) {
        this.hatali("Şube seçiniz");
      } else if (this.donem_id === null) {
        this.hatali("Dönem seçiniz");
      } else {
        localStorage.setItem("donem_id", this.donem_id);
        localStorage.setItem("donem_adi", this.donem_adi);
        localStorage.setItem("sube_id", this.sube_id);
        localStorage.setItem("sube_adi", this.sube_adi);
        localStorage.setItem("jeton", this.jeton);

        const sonuc = await kullanici_donem_izinleri(this.donem_id);

        if (sonuc.veriler.kod !== "basarili_yanit") {
          this.hatali("Kullanıcı izinleri bulunamadı");
          return;
        }
        const gelen_subeler = JSON.parse(localStorage.getItem("subeler"));
        const duz_dizi = gelen_subeler.flatMap((v) => v.donemler);

        localStorage.setItem(
          "roller",
          JSON.stringify(duz_dizi.find((v) => v.id === this.donem_id).roller)
        );

        const ogrenci_kimlik_bilgileri = duz_dizi.find(
          (v) => v.id === this.donem_id
        ).ogrenci?.kimlik;

        const ogrenci_bilgileri = duz_dizi.find(
          (v) => v.id === this.donem_id
        )?.ogrenci;

        localStorage.setItem("ogrenci_id", ogrenci_bilgileri?.id);

        localStorage.setItem(
          "ogrenci_ad_soyad",
          ogrenci_kimlik_bilgileri?.ad + " " + ogrenci_kimlik_bilgileri?.soyad
        );

        localStorage.setItem(
          "donem_izinleri",
          JSON.stringify(sonuc.veriler.sonuc)
        );
        if (this.$route.name !== "AnaSayfa") this.yonlendirme();
        //this.$router.push({ name: "AnaSayfa" }).catch(() => {});
        else {
          localStorage.setItem("donem_id", this.donem_id);
          localStorage.setItem("donem_adi", this.donem_adi);
          localStorage.setItem("sube_id", this.sube_id);
          localStorage.setItem("sube_adi", this.sube_adi);

          this.$emit("DialogKapat");
        }
      }
    },
    async hatali(gelen_mesaj) {
      this.$gizmo().ac({
        icon: "dikkat",
        aciklama: gelen_mesaj,
        baslik: "Dikkat",
        hayir: false,
      });
    },
    sube_secildi(i) {
      console.log("iii", i);
      if (i === null) {
        this.sube_id = null;
      } else {
        this.gelen_donemler = i.donemler;
        this.sube_id = i.id;
        this.sube_adi = i.ad;
        this.jeton = i.donemler[0].jeton;
      }
    },
    donem_secildi(i) {
      if (i === null) {
        this.donem_id = null;
      } else {
        this.donem_id = i.id;
        this.donem_adi = i.ad;
        this.jeton = i.jeton;
      }
    },
    cikis() {
      this.$emit("DialogKapat");
      localStorage.clear();
    },
    enter(e) {
      if (e.key === "Enter") {
        this.giris();
      }
    },
    yonlendirme() {
      if (JSON.parse(localStorage.getItem("roller"))?.includes("ÖĞRENCİ")) {
        this.$router.push({ name: "AnaSayfa" }).catch(() => {});
      } else if (
        JSON.parse(localStorage.getItem("roller"))?.includes("ÖĞRETMEN")
      ) {
        this.$router.push({ name: "OgrencilerimList" }).catch(() => {});
      } else {
        this.$router.push({ name: "AnaSayfa" }).catch(() => {});
      }
    },
  },

  data() {
    return {
      data: null,
      gelen_donemler: [],
      gelen_kurumlar: [],
      donem_id: null,
      sube_id: null,
      donem_adi: null,
      sube_adi: null,
      jeton: null,

      ilk_sube: { ad: null, id: null },
      ilk_donem: { ad: null, id: null },
    };
  },
  props: { modal: null },
  watch: {
    async modal() {
      this.data = JSON.parse(localStorage.getItem("subeler"));

      const donemler_sayisi = this.data[0].donemler.length - 1;

      this.ilk_sube.ad = this.data[0].ad;
      this.ilk_sube.id = this.data[0].id;
      this.ilk_donem.ad = this.data[0].donemler[donemler_sayisi].ad;
      this.ilk_donem.id = this.data[0].donemler[donemler_sayisi].id;
      this.jeton = this.data[0].donemler[donemler_sayisi].jeton;

      this.sube_id = this.ilk_sube.id;
      this.sube_adi = this.ilk_sube.ad;

      this.gelen_donemler = this.data[0].donemler;

      this.donem_id = this.ilk_donem.id;
      this.donem_adi = this.ilk_donem.ad;
      this.giris();
    },
  },
  components: {},
};
</script>
